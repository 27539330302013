import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import { hourlyRate, monthlySeo, experienceYears } from "../../components/Helpers";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import H from "../../components/Headline";
import ProfileCard from "../../components/ProfileCard";
import ContactForm from "../../components/ContactForm";
import ImgScreenshot from "../../components/ImgScreenshot.js";
import { useAlternateLangs } from "../../components/Hreflangs";

const breadCrumbLevels = {
    Hjem: "/",
    "Om meg": "/no/om",
    "SEO Frilanser": "/no/seo-frilanser"
};

// Hreflang data
const alternateLangs = useAlternateLangs(
    // replace with pages x-default
    "/en/seo-freelancer"
);


const SeoFreelancer = props => {

    return (
        <Layout location={props.location} alternateLangs={alternateLangs}>
            <React.Fragment>
                <SEO
                    title="SEO Frilanser til leie: Profil av M. Kupperschmidt"
                    description="Jeg er en frilans SEO-konsulent med mange års byråerfaring, og du kan ansette meg for å bringe nettstedet ditt til toppen av Googles rangeringer. Kontakt meg direkte."
                    lang="no"
                    image="matthias-kupperschmidt-presenting-at-founders-house-oct-2019"
                    alternateLangs={alternateLangs}
                    canonical="/no/seo-frilanser"
                />
                <MainContent article>
                    <Img
                        src="seo-freelancer/tag-management-consultant-hero-cropped.jpg"
                        alt="Matthias Kupperschmidt snakker om nøkkelordsvanskeligheter på SEO-konsulentmøte i København, Danmark, oktober 2019"
                    />
                    <Breadcrumb breadCrumbLevels={breadCrumbLevels} />
                    <H as="h1">Ansett en frilans SEO-konsulent</H>
                    <ProfileCard
                        tags={["SEO Content", "Backlinks", "Technical SEO", "Onpage SEO", "HTML/CSS/JavaScript", "React", "Python", "EN, DE, DA"]}
                        profession="SEO Konsulent"
                        rate={`fra ${monthlySeo}€ månedlig`}
                        location="Berlin, Tyskland / remote"
                        cta="Kontakt"
                    />
                    <p>Jeg er en SEO-freelancer, og du kan ansette meg for å få nettstedet ditt til toppen av Googles søkeresultater.</p>
                    <p>Jeg tilbyr samme tjeneste som et SEO-byrå, bare som en uavhengig SEO-konsulent. Jeg tar over planlegging, gjennomføring og kodeimplementering eller jobber sammen med ditt webbyrå.</p>
                    <p>Tidligere jobbet jeg i forskjellige SEO-byråer for bedriftskunder og e-handelsbedrifter med søkemotoroptimalisering dag ut og dag inn.</p>
                    <p>I 2018 tok jeg steget for å begynne å jobbe som frilans SEO-konsulent. I tillegg til å optimalisere Googles søkerangeringer, bygger jeg også profesjonelle sporingsoppsett med f.eks. Google Analytics og Google Tag Manager.</p>
                    <p>
                        Du kan lese mine <a target="_blank" noopener="true" href="https://www.trustpilot.com/review/bluerivermountains.com">anmeldelser</a> eller lære mer om min karriere på{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            LinkedIn.
                        </a>{" "}
                        Min personlige historie og andre detaljer er på min <Link to="/no/om">om meg-side</Link>.
                    </p>
                    <br />
                    <br />
                    <H as="h6" style={{ "textAlign": "center" }}>Hvem jeg har jobbet med</H>
                    <ImgScreenshot
                        src="about/clients_700px.png"
                        alt="klienter jeg har jobbet med"
                        className="article-img"
                    />
                    <br />
                    <br />
                    <br />


                    <H as="h2" style={{ "textAlign": "center" }}>Få et tilbud</H>
                    <ContactForm showHeadline={false} formName="ga consultant (NO) - kontakt skjema" />

                    <H as="h2">SEO-tjenester</H>
                    <p>
                        Generelt jobber jeg med alle viktige SEO-rangfaktorer for å optimalisere nettsteder for søkemotorer, akkurat som
                        ethvert SEO-selskap.
                    </p>
                    <p>Her er en kort liste over mine SEO-tjenester:</p>
                    <ul>
                        <li>On-page & Off-page optimalisering</li>
                        <li>Skalerbare strategier for lenkebygging</li>
                        <li>Innholdsskaping på flere språk</li>
                        <li>JavaScript SEO</li>
                        <li>Lastfartoptimalisering</li>
                        <li>Teknisk SEO-rådgivning</li>
                        <li>Nettsidestruktur</li>
                        <li>Accelerated Mobile Pages (AMP)</li>
                        <li>ingen lokal SEO</li>
                    </ul>
                    <p>Selv om jeg fokuserer på SEO, er jeg godt klar over at det finnes andre kraftige betalte trafikkilder i tillegg til søketrafikk, f.eks. Facebook, Twitter eller Google-annonser. Derfor implementerer jeg mine SEO-teknikker med andre internettmarkedsføringsdisipliner i tankene, slik at vi kan <b>utnytte organisk trafikk for retargeting</b> i betalte søkekampanjer og sosiale mediekampanjer også.</p>
                    <p>Takket være min kunnskap om webutvikling, kan jeg også hjelpe deg med webdesignoppgaver i HTML, CSS og JavaScript eller konfigurasjoner i Google Tag Manager og Google Analytics.</p>

                    <H as="h2">Timepris og kostnader</H>
                    <p>Min sats for frilans SEO-rådgivning er <b>{hourlyRate} €</b> netto per time. De totale kostnadene for et SEO-prosjekt avhenger av dine egne mål, men også av din nåværende nettsideoptimalisering og innsatsen til dine konkurrenter.</p>
                    <p>Nedenfor er noen påvirkningsfaktorer som bestemmer prisen på min SEO-rådgivning:</p>
                    <ul>
                        <li>Totalt antall sider på domenet</li>
                        <li>Alder og autoritet til domenet</li>
                        <li>Konkurranseevne i nisjen</li>
                        <li>Tekniske utfordringer</li>
                        <li>Tilpasningsevne til frontend</li>
                        <li>Antall språk vi optimaliserer for</li>
                        <li>Hvor mye nytt nettstedinnhold vil vi trenge?</li>
                        <li>Hvilken lenkebyggingsstrategi er nødvendig?</li>
                        <li>Er det andre partnere som dekker oppgaver for innhold eller lenker?</li>
                    </ul>
                    <br />
                    <p>Time minste er vanligvis <b>minst 15 arbeidstimer per måned</b>.</p>
                    <p>Potensielle kunder må stole på vurderingen av SEO-eksperten om hvor sterk konkurransen er, hva slags nettsideoptimalisering som kreves, og hvilke muligheter som finnes.</p>
                    <p>Les videre nedenfor hvor jeg forklarer <Link to="/no/seo-frilanser">hvordan du ansetter den rette SEO-freelanceren</Link>.</p>
                    <p>For å bestemme prisen, defineres omfanget av kontrakten ved prosjektstart, basert på faktorene ovenfor.</p>
                    <p>For <b>pågående SEO-optimalisering</b> er en månedlig prosjektomfang basert på oppgaver og milepæler. Basert på timeprisen, planlagt SEO-innsats og nødvendig arbeidstid, er SEO-kostnader planbare og begrenset for hver måned.</p>
                    <p>Etter en avtale kan jeg begynne arbeidet mitt så snart jeg får tilgang til nettstedet og relaterte verktøy.</p>
                    <p>Jeg leverer <b>SEO-rapporter</b> i form av et live dashboard, slik at du har full oversikt over nøkkelordrangeringer og den generelle suksessen til min SEO-tjeneste. Jeg betaler for en rekke SEO-verktøy selv og inkluderer dem i prisen.</p>
                    <p>SEO-kunder kan noen ganger bare kreve et <b>lite engangsprosjekt</b>. For eksempel en <b>lastfartoptimalisering</b>, en <b>teknisk SEO-revisjon, konkurrentanalyse</b> eller implementering av viktige landingssider som <b>AMP-sider</b>.</p>
                    <p>Slike mindre prosjekter er omfangsmessig individuelt og er selvfølgelig ikke relatert til faktorene ovenfor.</p>


                    <H as="h2">Fordeler med en SEO-freelancer vs. et byrå</H>
                    <p>Generelt sett passer frilansere godt for kunder som <b>vet hva de vil ha</b>.</p>
                    <p>Vanligvis er dette en bedrift som har jobbet med et byrå før og har en dyktig person internt. De har mindre behov for veiledning og ser heller etter pålitelig utførelse. De vil ha jobben gjort med høy grad av sikkerhet uten for mange powerpoint-presentasjoner og oppsalgstaktikker.</p>
                    <p>Frilansere leverer nettopp det. De fokuserer på utførelse og har begrenset tid til kommunikasjon.</p>
                    <p>Oppsummert er fordelene ved å jobbe med en frilanser som følger:</p>
                    <ul>
                        <li><b>bedre pris</b></li>
                        <li><b>fleksibilitet</b> fordi du kan ansette etter behov</li>
                        <li>flere år med <b>erfaring</b></li>
                        <li>mer <b>spesialiserte</b> profiler tilgjengelig</li>
                        <li><b>kommunikasjon</b> direkte med spesialisten</li>
                    </ul>

                    <H as="h2">Ulemper</H>
                    <p>Ulemper ved å jobbe med en frilanser er vanligvis relatert til, men ikke begrenset til, skalerbarhet. Til syvende og sist er det én person du jobber med som bare har to hender. Med et team får du gjort mer arbeid på kortere tid.</p>
                    <p>Andre potensielle ulemper er:</p>
                    <ul>
                        <li><b>Begrensninger på skalering:</b> Hvis du forventer at antall oppgaver øker, kan én person kanskje ikke være nok for jobben.</li>
                        <li><b>Begrensninger på grunn av ekspertise:</b> Et byrå vil ha et bredere utvalg av folk tilgjengelig for råd.</li>
                        <li><b>Pålitelighet:</b> Sykdom eller uventede livsforstyrrelser kan bedre kompenseres med et team.</li>
                        <li><b>Personlighetskonflikt:</b> Hvis du ikke kommer overens med din frilanser, må du kanskje gå videre og ha kastet bort tid. Et byrå vil kunne sette deg i kontakt med en annen person.</li>
                    </ul>


                    <H as="h2">Hvordan ansette den rette frilanseren?</H>
                    <p>Når du ansetter SEO-frilansere, er det viktig å validere erfaringen og SEO-ferdighetene til hver profil.</p>
                    <p>For eksempel vil du sørge for at teknologien på nettstedet ditt samsvarer med frilanserens tidligere prosjekter. Hvis du driver et WordPress-nettsted, vil de fleste frilansere føle seg komfortable med å jobbe med det. Men hvis du har en veldig tilpasset webstack med f.eks. React eller Angular i frontend og Sitecore eller Umbraco i backend, vil du sikre at det ikke medfører noen utfordringer.</p>
                    <p>Individuelle SEO-spesialister er kanskje ikke vant til å implementere endringer via Git, men nettstedet ditt krever det. Så diskuter prosessen med å implementere endringer på nettstedet for å avdekke potensielle flaskehalser.</p>
                    <p>Noen selskaper bygger sin webtilstedeværelse selv med en intern utvikler, mens andre jobber med et webbyrå. Dette har implikasjoner for SEO-rådgivning siden endringer enten kan implementeres direkte eller må gå gjennom et eksternt byrå. Den faktoren alene påvirker kostnadene og den nødvendige tiden for en SEO-kampanje fordi flere lag med kommunikasjon er nødvendige før en endring blir implementert.</p>
                    <p>Så uavhengige SEO-eksperter må være komfortable med det gitte oppsettet av interne og eksterne team og vurdere dens innvirkning på den overordnede SEO-strategien.</p>
                    <p>Også tidligere arbeidsplasser og hvor frilanseren har jobbet før spiller en rolle for å trekke konklusjoner om arbeidsmoral og pålitelighet. Inngangsbarrieren for SEO-frilansere fra Upwork er ganske lav og kan derfor kanskje ikke levere den rette kandidaten (ikke at det ikke er mulig, men). Derfor håper jeg å tilby litt gjennomsiktighet ved å dele med deg min{" "}
                        <a target="_blank" noopener="true" href="https://www.linkedin.com/in/matthiaskupperschmidt">
                            LinkedIn-profil
                        </a>{" "}
                        på forhånd.
                    </p>
                    <p>Når du finner noen, bør en enkel e-post eller utfylt kontaktskjema være nok til å starte en samtale. En profesjonell SEO-frilanser tar imidlertid ikke på seg hvert prosjekt som kommer på bordet. Og det er bra for kundene, så ikke ta en avvisning personlig.</p>
                    <p>Det finnes varierende SEO-strategier for forskjellige nisjer og nøkkelordintensjoner. Frilansere vil sørge for at nisjens krav og nettstedets teknologi samsvarer med deres ekspertise.</p>
                    <p>Hvis de ikke gjør det, bør kundene være skeptiske. Med mindre du trenger en noe standard SEO-oppgave levert, vil du finne en SEO-frilanser som er en ekspert på din sak.</p>
                    <p>Etter at prosjektets omfang og kostnader er avtalt skriftlig, trenger frilanseren tilgang til nettstedet. Det betyr tilgang til frontend, CMS og analyser og potensielt en FTP-server. Så snart tilgang er gitt, er SEO-eksperten ansatt og arbeidstiden starter offisielt.</p>

                    <H as="h2">Hvordan ble jeg en frilanser innen søkemotoroptimalisering?</H>
                    <p>Jeg bygde min første private hjemmeside som 14-åring for å dele min filmsamling med skolevenner. I landsbyen vår var jeg også barnet som reparerte datamaskiner og gjennomførte mange operativsystem-installasjoner.</p>
                    <p>
                        Da jeg var 19, bygde jeg Kickerkult.de, en nettbutikk for bordfotball og begynte med søkemotoroptimalisering for min egen
                        nøkkelordforskning. Den gangen var det nesten nok å optimalisere meta keywords og titler.
                    </p>
                    <p>Å fikle med nettsteder og teknologi som helhet har alltid vært gøy for meg, så jeg må ha naturlig valgt å jobbe fulltid i webbyråer og online markedsføring senere. Kollegene og prosjektene i disse byråene var en god innflytelse for å utvide ferdighetssettet mitt og gjøre arbeidsflytene og prosessene mine mer profesjonelle.</p>
                    <p>Etter å ha hatt min del av SEO-jobber, ble fremgang sammen med et digitalt markedsføringsbyrå stadig vanskeligere, så frilans SEO-rådgivning ble det logiske neste karrieretrinnet for meg.</p>
                    <p>De fleste <a href="https://www.statista.com/statistics/946967/freelancers-in-europe-by-sector/" target="_blank">frilansere i Europa (39%) jobber innen markedsføring og kommunikasjon</a>. Jeg ønsket å gå dypere inn i mitt fagområde og kontrollere hvilke prosjekter jeg jobber med, så frilansing ble det neste skrittet for å sikre at hver time brukes på en meningsfull oppgave.</p>
                    <p>Det viser seg at jeg ikke er helt alene med den tankegangen. Faktisk er <a href="https://apo.org.au/sites/default/files/resource-files/2016-05/apo-nid201721.pdf" target="_blank">frilansere eller iPros den raskest voksende gruppen på EU arbeidsmarkedet siden 2004</a> og <a href="https://news.malt.com/wp-content/uploads/2019/02/FREELANCING-IN-EUROPE-2-1.pdf" target="_blank">de utgjør omtrent 7% av den totale arbeidsstyrken i EU</a>.</p>
                    <p>Jeg kan nå bruke mer tid på innhold, nøkkelordforskning og lenkebygging, fordi jeg trenger mindre tid til administrative oppgaver eller prosjektledelse, mens kundene mine får mer faktisk arbeid for sitt budsjett. Å frilanse på heltid gir meg derfor en raskere tilbakemeldingssløyfe, som er nødvendig for å konkurrere og vanligvis ikke oppnås i et SEO-byrå.</p>
                    <p>Jeg har jobbet som frilans SEO-spesialist siden 2018. Jeg jobber mest med bedriftskunder som har multinasjonale nettsteder, samt e-handel butikker som følger en innholdsmarkedsføring-drevet tilnærming. Jeg driver ikke med lokal SEO.</p>

                    <H as="h3">Ser du etter SEO-hjelp?</H>
                    <p>
                        Hvis du ser etter en frilans SEO-ekspert for å hjelpe deg med dine Google-rangeringer, er du velkommen til å kontakte
                        meg. Jeg kommer vanligvis tilbake med en vurdering av situasjonen og en plan inkludert et tilbud.
                    </p>
                    <p>
                        Du kan ellers finne mer informasjon på min <Link to="/no/om">om-side</Link> og potensielt noen
                        interessante lesninger på min <Link to="/no/blogg">blogg</Link>.
                    </p>
                    <H as="h4" style={{ "textAlign": "center" }}>Kontakt meg for et uforpliktende tilbud.</H>
                    {/* tar av schema på grunn av potensielle dårlige implikasjoner */}
                    {/* <Helmet>
    <script type="application/ld+json">{Schema}</script>
</Helmet> */}
                </MainContent>
            </React.Fragment>

        </Layout>
    );
};

export default SeoFreelancer;

